import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Grid from "@material-ui/core/Grid"
import { useState } from "react"
import { useQuery } from "@apollo/react-hooks"

import gql from "graphql-tag"
import { Query } from "react-apollo"

import DOMPurify from "dompurify"

const Blog = () => {
  const APOLLO_QUERY = gql`
    {
      posts {
        edges {
          node {
            postId
            featuredImage {
              node {
                mediaItemUrl
              }
            }
            title
            excerpt
          }
        }
      }
    }
  `

  const sanitizer = DOMPurify.sanitize

  const showBlogPost = id => {
    navigate("/blog_post?id=" + id)
  }

  const [posts, setPosts] = useState([])

  const { data } = useQuery(APOLLO_QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: () => {
      console.log(data)
      setPosts(data?.posts?.edges)
    },
  })

  const blogs = posts?.map((item, index) => {
    // let bgImg = item?.node?.featuredImage?.node?.mediaItemUrl ? item?.node?.featuredImage?.node?.mediaItemUrl : DefaultBlogImg
    return (
      <Grid
        key={index}
        container
        className={index % 2 === 0 ? "blog_item_grey" : "blog_item_white"}
      >
        {item?.node?.featuredImage && (
          <Grid
            className="blog_image"
            style={{
              backgroundImage: `url(${item?.node?.featuredImage?.node?.mediaItemUrl})`,
            }}
          ></Grid>
        )}
        <Grid
          className={index % 2 === 0 ? "blog_intro_right" : "blog_intro_left"}
        >
          <h1>{item?.node?.title}</h1>
          <Grid>
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizer(item?.node?.excerpt),
              }}
            ></div>
          </Grid>
          <Grid>
            <Grid
              onClick={() => showBlogPost(item?.node?.postId)}
              className="sales_button"
            >
              <div>READ MORE</div>
              <div>`{">"}`</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  })

  return (
    <Layout>
      <SEO title="Blog" />

      <Grid container direction="row">
        <Grid container className="grid1">
          <Grid container className="blog1">
            <h2>Who, What, When, Where, Why? And perhaps …How?</h2>
          </Grid>
        </Grid>

        {blogs}
      </Grid>
    </Layout>
  )
}

export default Blog
